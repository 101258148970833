import * as React from 'react';
import { useEffect } from 'react';

// --- ABOUT DESCRIPTION COMPONENT ---
    // manifestTitle       --> Text title from Strapi
    // manifestDescription --> Text description from Strapi
const AboutDescription = (props: { 
    aboutTitle: string, 
    aboutDescription: string
}) => {

    useEffect(() => {
    })

    return (
        <>
            <div className="min-h-screen">
                <div className='flex flex-col'>
                    <div className='hidden text-lg uppercase lg:flex'>about</div>
                    <div className='hidden font-bold pt-7 text-7xl lg:flex'>{props?.aboutTitle && props.aboutTitle}</div>
                    <div className='flex flex-wrap w-full pt-8 space-x-2 text-3xl leading-tight text-center lg:pt-16 lg:w-3/4 lg:text-5xl lg:text-left'>
                        {props?.aboutDescription && props.aboutDescription.split(' ').map((letter: string, index: number) => {
                            let letterStyle: any = {"animationDelay": (1 + index / 4) + "s"}
                            return(     
                                <div className='text-cardinal-white enlightLetter' style={letterStyle} key={index}>{letter}</div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )

}

export default AboutDescription;